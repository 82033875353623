import * as React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql, Link } from "gatsby"
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n/initI18n'
import Footer from './Footer'
import NavBar from './NavBar'
import './index.css'

const Layout = props => {
  const { children, languageRedirect = false } = props
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div lang="fi">
      <I18nextProvider i18n={i18n}>
        <NavBar languageRedirect={languageRedirect} />
        <div className="page-container">
          <main>{children}</main>
        </div>
        <Footer />
      </I18nextProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  languageRedirect: PropTypes.bool.isRequired,
}

// Layout.defaultProps = {
//   languageRedirect: false,
// }

export default Layout
