import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// const handleLocalStorageLanguage = () => {
//   let savedLanguage = window.localStorage.getItem('language');

//   if(!savedLanguage) {
//     if (window.location.pathname.match('/en')) {
//       window.localStorage.setItem('language', 'en')
//       savedLanguage = 'en'
//       } else {
//       window.localStorage.setItem('language', 'fi')
//       savedLanguage = 'fi'
//       }
//     }

//   return savedLanguage
// }

// const localStorageLanguage = typeof window !== 'undefined' && handleLocalStorageLanguage()

let language = /*localStorageLanguage ||*/ 'fi'

i18n.use(initReactI18next).init({
  ns: ['common'],
  defaultNS: 'common',
  lng: language,
  debug: process.env.NODE_ENV === 'development',
  // fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: false },
  resources: {
    en: {
      common: require('./locales/en.json'),
    },
    fi: {
      common: require('./locales/fi.json'),
    },
  },
})

export default i18n
