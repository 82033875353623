import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
// import { Button } from './Button';
import { Link } from 'gatsby'
import i18n from '../i18n/initI18n'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../context/context'
// import NavDropdownLanguage from './NavDropdownLanguage';
import NavDropdownUser from './NavDropdownUser'
import burgerIcon from '../static/icons/burger.svg'
import closeIcon from '../static/icons/close.svg'

function Navbar(props) {
  const { languageRedirect } = props
  const { t } = useTranslation()
  const state = useContext(StateContext)
  const [click, setClick] = useState(false)
  const [languageDropdown, setLanguageDropdown] = useState(false)
  const [userDropdown, setUserDropdown] = useState(false)
  const languageLabels = { en: t('ENGLISH'), fi: t('FINNISH') }
  const windowCheck = typeof window !== 'undefined'

  const handleClick = () => {
    setClick(!click)
    setLanguageDropdown(true)
    setUserDropdown(true)
  }

  const closeMobileMenu = () => setClick(false)

  const onMouseEnterLanguageDropdown = () => {
    if (windowCheck && window.innerWidth < 900) {
      return
    } else {
      setLanguageDropdown(true)
    }
  }

  const onMouseLeaveLanguageDropdown = () => {
    if (windowCheck && window.innerWidth < 900) {
      return
    } else {
      setLanguageDropdown(false)
    }
  }

  const onMouseEnterUserDropdown = () => {
    if (windowCheck && window.innerWidth < 900) {
      return
    } else {
      setUserDropdown(true)
    }
  }

  const onMouseLeaveUserDropdown = () => {
    if (windowCheck && window.innerWidth < 900) {
      return
    } else {
      setUserDropdown(false)
    }
  }

  return (
    <>
      <nav className="navbar">
        <a className="mobile-hide" href="https://kyrodistillery.com/">
          {t('KYRO_EXTERNAL_LINK')}
        </a>

        <Link id="menu-home" to={`/`}>
          {t('HOMEPAGE_LINK')}
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          {click ? <img src={closeIcon}></img> : <img src={burgerIcon}></img>}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          {/* <li
            className='nav-item'
            onMouseEnter={onMouseEnterLanguageDropdown}
            onMouseLeave={onMouseLeaveLanguageDropdown}>
            <div
              className='nav-links mobile-hide'
              onClick={closeMobileMenu}>
              {languageLabels[i18n.language]}
            </div>
            {languageDropdown && <NavDropdownLanguage languageRedirect={languageRedirect} />}
          </li> */}
          <li
            className="nav-item"
            onMouseEnter={onMouseEnterUserDropdown}
            onMouseLeave={onMouseLeaveUserDropdown}
          >
            <Link
              to={state.user ? '/app/dashboard' : '/app/login'}
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <span className="mobile-hide">
                {state.user && state.user.name}
              </span>
              {!state.user && t('LOGIN')}
            </Link>
            {userDropdown && state.user && (
              <NavDropdownUser mainNavClick={setClick} />
            )}
          </li>
          <li className="nav-item desktop-hide">
            <a className="nav-links" href="https://kyrodistillery.com/">
              {t('KYRO_EXTERNAL_LINK')}
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}

Navbar.propTypes = {
  languageRedirect: PropTypes.bool.isRequired,
}

export default Navbar
