import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { DispatchContext, StateContext } from '../context/context'
import { UserPool } from '../context/context'

const Logout = props => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const { t } = useTranslation()

  const onSubmit = async e => {
    e.preventDefault()

    props.mainNavClick(false)

    const user = UserPool.getCurrentUser()

    if (user) {
      user.signOut(/*data => console.log("logout", data)*/)
      dispatch({ type: 'LOGOUT' })
      navigate('/app/login')
    }
  }

  return (
    <div className="dropdown-link" onClick={onSubmit}>
      {t('LOGOUT')}
    </div>
  )
}

Logout.propTypes = {
  mainNavClick: PropTypes.func.isRequired,
}

export default Logout
