import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Logout from './Logout'

const NavDropdownUser = props => {
  const { t } = useTranslation()
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        // onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        <li className="nav-item">
          <Link
            className="dropdown-link"
            to="/app/dashboard"
            onClick={() => props.mainNavClick(false)}
          >
            {t('NAV_PORTAL_LABEL')}
          </Link>
        </li>
        <li className="nav-item">
          <Logout mainNavClick={props.mainNavClick} />
        </li>
      </ul>
    </>
  )
}

NavDropdownUser.propTypes = {
  mainNavClick: PropTypes.func.isRequired,
}

export default NavDropdownUser
