import React from 'react'
import { Link } from 'gatsby'
import i18n from '../i18n/initI18n'
import { useTranslation } from 'react-i18next'
import logo from '../static/images/kdc-logo.png'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer>
      <img className="logo" src={logo} />
      <p>
        © Kyrö Distillery Company {new Date().getFullYear()} – Trust is our
        dearest value -{' '}
        <Link to={`/tietosuojaseloste`}>{t('PRIVACY_POLICY_LINK')}</Link>
      </p>
      <Link to={`/sopimusehdot-ja-tietosuoja`}>
        {t('TERMS_AND_CONDITIONS_LINK')}
      </Link>
      <p>
        {t('CONTACT_US').toUpperCase()}:{' '}
        <a href="mailto:loans@kyrodistillery.com">loans@kyrodistillery.com</a>
      </p>
    </footer>
  )
}

export default Footer
